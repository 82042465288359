import { ucfirst } from '@/utils/helpers';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE,
} from '@/lib/constants';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { DateTime } from 'luxon';

const getStepOneCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection;
const getStepTwoCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason.length;
const getStepThreeCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason
  && tasq?.responseData?.user_response?.selection_details?.action;
const getStepFourCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason
  && tasq?.responseData?.user_response?.selection_details?.action
  && tasq?.responseData?.user_response?.corrected_state_change_date;

const getStepLabel = (tasq: TasqJob) => {
  let label = 'No action';
  if (tasq.stepOneCompleted) {
    label = 'Validated';
  }
  if (tasq.stepTwoCompleted) {
    label = 'Labeled';
  }
  if (tasq.stepThreeCompleted) {
    label = 'Actioned';
  }
  if (tasq.stepFourCompleted) {
    label = 'Resolved';
  }
  return label;
};







const getDateAgoText = (tasq: TasqJob) => {
  let date = '';
  // @ts-ignore
  const waiting = tasq?.snoozed?.wait_until_reason && tasq?.snoozed?.until_date;
  let time = tasq?.assignmentTime;
  if (waiting) {
    // @ts-ignore
    time = tasq?.snoozed.until_date;
  }
  if (tasq?.predictionType === TASQ_OFF_TARGET_TYPE) {
    time = tasq?.offTargetStartDate;
  }
  if (tasq?.predictionType === TASQ_STATE_CHANGE_TYPE
    || tasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE
  ) {
    time = tasq?.stateChangeStart;
  }
  if (!time) {
    return date;
  }
  // @ts-ignore
  const timeVal = DateTime.fromISO(time);
  let value = DateTime.local().diff(timeVal, 'days').toObject()?.days?.toFixed();
  if (waiting) {
    value = timeVal.diff(DateTime.local(), 'days').days.toFixed();
  }
  if (value && value !== '0') {
    date = value === '1'
      ? `${value} day` : `${value} days`;
    if (waiting) {
      date = `in ${date}`;
    }
  }
  if (value === '0') {
    date = 'Today';
  }
  return {
    text: date,
    value: Number(value),
  };
};

const getBgColorClass = (tasq: TasqJobResponse) => {
  // if (tasq.isWorkflowGen) return 'bg-color--pink';
  switch (tasq.PredictionType) {
    case TASQ_PREDICTION_TYPE:
      return 'bg-tasqGreen';
    case TASQ_FAILURE_TYPE:
      return 'bg-tasqRed';
    case TASQ_DOWN_TYPE:
    case TASQ_OFF_TARGET_TYPE:
      return 'bg-tasqOrange';
    case TASQ_LABEL_TYPE:
      return 'bg-tasqBlueLight';
    case TASQ_PRODUCING_TYPE:
    case TASQ_PM_TYPE:
    case TASQ_GAUGE_TYPE:
    case TASQ_REGULATORY_TYPE:
    case TASQ_SITE_CHECK_TYPE:
    case TASQ_NO_COMMS_TYPE:
      return 'bg-tasqGrayDark';
    case TASQ_SETPOINT_TYPE:
      return 'bg-tasqBlue';
    default:
      return 'bg-tasqYellowDark';
  }
};

const getOverriddenTasqName = (tasq: TasqJobResponse) => {
  switch (tasq.PredictionType) {
    case TASQ_PROLONGED_ANOMALY_TYPE:
    case TASQ_STATE_CHANGE_TYPE:
      return 'Anomaly';
	case TASQ_WELL_TEST_TYPE:
	  return "Well test"
    default:
      return ucfirst(tasq.PredictionType || '');
  }
};

const mapDefaultTasq = (t, userEmail = ''): TasqJob => {
  // const workflowDetails = t.WorkflowCondition
  //   ? mapConditionDetails(Object.values(JSON.parse(t.WorkflowCondition))) : null;
  const tasq = {
    id: t.PredictionID,
    predictionType: t.PredictionType,
    engineerType: ucfirst(t.PredictionType || ''),
    wellName: t.NodeID === 'Example Job' ? 'Braum Family 9-4-1XH' : t.NodeID,
    date: t?.StateChangeDate,
    stateChangeStart: t.StateChangeDate,
    stateChangeEnd: t.StateChangeDateEnd,
    time: t.time,
	lastResponseTime: t.LastResponseTime ? t.LastResponseTime : [],
    // states: t.States,
    stateChangeDate: t.StateChangeDate,
    responseData: t.ResponseData,
    viewed: t.Viewed,
    snoozed: t.snoozed,
    isViewed: (t.Viewed || []).includes(userEmail),
    assignee: t.Assignee,
    isLocked: t.locked && (t.locked || {}).Status,
    isRepetitive: t.repetitive,
    isSnoozed: t.snoozed,
    isRejected: t.rejected,
    noComms: t.PredictionType === TASQ_NO_COMMS_TYPE,
    isWorkflowGen: t.WorkflowDetailsID || false,
    workflowTasqId: t.WorkflowTaskID,
	workflowDetailsID: null,
    // workflowDetails: t.WorkflowCondition
    //   ? mapConditionDetails(Object.values(JSON.parse(t.WorkflowCondition))) : null,
    // workflowResultDict: (t?.workflow_condition_results_dict || [])[0], // eslint-disable-line
    username: t.Assignee.initial_assignment, // eslint-disable-line
    reassignedUserName: t.Assignee.reassignment.new_assignee || '', // eslint-disable-line
    isManuallyAdded: t.ManuallyAssigned,
    offTargetStartDate: t.OffTargetStartDate,
    // prolongedResponseData: t.ProlongedResponseData,
    isWatched: t?.Watchlist?.includes(userEmail),
    assignmentTime: t?.AssignmentTime,
    completed: t.Closed,
    defermentValue: t?.DefermentValue,
    comments: t.comments,
    bgColorClass: getBgColorClass(t),
    overriddenName: getOverriddenTasqName(t),
    stepOneCompleted: false,
    stepTwoCompleted: false,
    stepThreeCompleted: false,
    stepFourCompleted: false,
	respondedToday: false,
	respondedOneDayAgo: false,
	respondedTwoDaysAgo: false,
	respondedThreeDaysAgo: false,
	respondedFourDaysAgo: false,
    stepLabel: '',
    dateAgoText: '',
	wellTestData: null
  };

if (t.WorkflowDetailsID != null) {
	tasq.workflowDetailsID = t.WorkflowDetailsID
}
if (t.Payload != null && (t.PredictionType.toLowerCase() == "anomaly" || t.PredictionType.toLowerCase() == "state change")) {
	tasq.stateChangeStart = t.Payload.Record.StateChangeDate
	tasq.stateChangeEnd = t.Payload.Record.StateChangeDateEnd
	tasq.stateChangeDate = t.Payload.Record.StateChangeDate
	tasq.date = t.Payload.Record.StateChangeDate
} else if ("off-target" == t.PredictionType.toLowerCase()) {
	tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(" ", "T")
	tasq.defermentValue = t.Payload.MCF
}

  var todaysDate = new Date();
  var oneDayAgo = new Date().setDate(new Date().getDate()-1);
  var twoDayAgo = new Date().setDate(new Date().getDate()-2);
  var threeDayAgo = new Date().setDate(new Date().getDate()-3);
  var fourDayAgo = new Date().setDate(new Date().getDate()-4);
  for (var x = 0; x < tasq.lastResponseTime.length; x++) {
	var movedOutside = tasq.lastResponseTime[x] + ".000Z"
	var lastResponseLocalTime = new Date(movedOutside)
	// call setHours to take the time out of the comparison
	if(lastResponseLocalTime.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
		// Date equals today's date
		tasq.respondedToday = true
	}
	if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(oneDayAgo).setHours(0,0,0,0)) {
		// Date equals today's date
		tasq.respondedOneDayAgo = true
	}
	if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(twoDayAgo).setHours(0,0,0,0)) {
		// Date equals today's date
		tasq.respondedTwoDaysAgo = true
	}
	if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(threeDayAgo).setHours(0,0,0,0)) {
		// Date equals today's date
		tasq.respondedThreeDaysAgo = true
	}
	if(lastResponseLocalTime.setHours(0,0,0,0) == new Date(fourDayAgo).setHours(0,0,0,0)) {
		// Date equals today's date
		tasq.respondedFourDaysAgo = true
	}
  }

  // @ts-ignore
  tasq.stepOneCompleted = getStepOneCompleted(tasq);
  // @ts-ignore
  tasq.stepTwoCompleted = getStepTwoCompleted(tasq);
  // @ts-ignore
  tasq.stepThreeCompleted = getStepThreeCompleted(tasq);
  // @ts-ignore
  tasq.stepFourCompleted = getStepFourCompleted(tasq);
  // @ts-ignore
  tasq.stepLabel = getStepLabel(tasq);
  // @ts-ignore
  tasq.dateAgoText = getDateAgoText(tasq).text;
  // @ts-ignore
  tasq.dateAgoValue = getDateAgoText(tasq).value;
  // @ts-ignore


  if (t.TaskID != null && t.ClusterID != null && t.GanttChartID != null) {
	  // @ts-ignore
	tasq.wellTestData = {
		// @ts-ignore
		taskID: t.TaskID,
		// @ts-ignore
		clusterID: t.ClusterID,
		// @ts-ignore
		ganttChartID: t.GanttChartID,
	}
  }

  // @ts-ignore
  return tasq;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultTasq,
};
